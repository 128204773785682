'use client';

import { Locale, useIntl } from '@prezly/theme-kit-nextjs';
import { useBreakpointUp } from 'hooks';

import { translations } from '@/custom/i18n';

import { Logo, Shape } from './Logo';

import styles from './Footer.module.scss';

export function Footer() {
    const { formatMessage, locale } = useIntl();
    const isUpLg = useBreakpointUp('lg');

    const { code } = Locale.from(locale);
    const isNl = code.includes('nl');

    const links = [
        {
            href: isNl ? 'https://ag.be/privacy/nl' : 'https://ag.be/privacy/fr',
            text: 'Privacy',
        },
        {
            href: isNl
                ? 'https://ag.be/privacy/nl/cookiebeleid'
                : 'https://ag.be/privacy/fr/politique-des-cookies',
            text: 'Cookies',
        },
        {
            href: isNl
                ? 'https://ag.be/particulieren/particulieren/content/dam/transversal/cookieservice/documents/TermsOfUse_NL.pdf'
                : 'https://ag.be/particuliers/particuliers/content/dam/transversal/cookieservice/documents/TermsOfUse_FR.pdf',
            text: 'Terms of use',
        },
        {
            href: isNl
                ? 'https://ag.be/particulieren/nl/algemene-voorwaarden-en-contractuele-documenten'
                : 'https://ag.be/particuliers/fr/conditions-generales-et-documents-contractuels',
            text: formatMessage(translations.custom.contracts),
        },
        {
            href: isNl
                ? 'https://ag.be/over-ag/nl/whistleblowing-procedure-bij-AG'
                : 'https://ag.be/a-propos-d-ag/fr/procedure-alerte-ag',
            text: formatMessage(translations.custom.report),
        },
    ];

    return (
        <footer className={styles.footer}>
            <div className={styles.copy}>{formatMessage(translations.custom.copyright)}</div>
            <div className={styles.links}>
                {links.map((link, index) => (
                    <a key={index} href={link.href} className={styles.link}>
                        {link.text}
                    </a>
                ))}
            </div>
            {isUpLg && (
                <div className={styles.logoWrapper}>
                    <Logo width={122} height={66} className={styles.logo} />
                    <Shape className={styles.shape} />
                </div>
            )}
        </footer>
    );
}
