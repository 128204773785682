'use client';

import type { Locale } from '@prezly/theme-kit-nextjs';
import { IconArrowRight, IconClose, IconMenu } from 'icons';
import type { ReactNode } from 'react';
import { useState } from 'react';

import type { AppUrlGeneratorParams } from '@/adapters/server';
import { Link } from '@/components/Link';
import { Modal } from '@/components/Modal';

import styles from './MobileMenuButton.module.scss';

type Props = {
    localeCode: Locale.Code;
    items: Array<{
        href: string | AppUrlGeneratorParams;
        children: ReactNode;
    }>;
};

export function MobileMenuButton({ items: items }: Props) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className={styles.wrapper}>
            <button type="button" className={styles.button} onClick={() => setIsActive(true)}>
                <IconMenu width={16} height={16} />
            </button>
            <Modal
                id="menu-widget"
                isOpen={isActive}
                onClose={() => setIsActive(false)}
                className={styles.mobileMenu}
                wrapperClassName={styles.mobileWrapper}
                backdropClassName={styles.backdrop}
            >
                <div className={styles.header}>
                    <strong>Menu</strong>
                    <button onClick={() => setIsActive(false)} className={styles.close}>
                        <IconClose width={16} height={16} />
                    </button>
                </div>
                {items.map((item) => (
                    <Link
                        key={typeof item.href === 'string' ? item.href : item.href.routeName}
                        onClick={() => setIsActive(false)}
                        className={styles.item}
                        href={item.href}
                    >
                        <span>{item.children}</span>
                        <IconArrowRight width={16} height={16} />
                    </Link>
                ))}
            </Modal>
        </div>
    );
}
