'use client';

import { Locale, useIntl } from '@prezly/theme-kit-nextjs';
import type { IconComponentType } from 'icons';
import { IconGlobe } from 'icons';

import { translations } from '@/custom/i18n';
import { ChevronDownIcon } from '@/custom/icons';

import styles from './TopMenuItems.module.scss';

interface HeaderItem {
    label: string;
    link: string;
    icon?: IconComponentType;
    children?: HeaderItem[];
}

export function TopMenuItems() {
    const { formatMessage, locale } = useIntl();
    const code = Locale.from(locale).lang;

    const topMenuItems: HeaderItem[] = [
        {
            label: formatMessage(translations.custom.individuals),
            link: `https://ag.be/particulieren/${code}`,
        },
        {
            label: formatMessage(translations.custom.smes),
            link: `https://ag.be/professioneel/${code}`,
        },
        {
            label: formatMessage(translations.custom.employeeBenefits),
            link:
                code === 'fr'
                    ? 'https://ag.be/employeebenefits/fr/werkgevers'
                    : 'https://ag.be/employeebenefits/nl/employeurs',
        },
        {
            label: formatMessage(translations.custom.allWebsites),
            link: '#',
            icon: IconGlobe,
            children: [
                {
                    label: formatMessage(translations.custom.jobs),
                    link: `https://ag.be/jobs/${code}`,
                },
                {
                    label: formatMessage(translations.custom.aboutAg),
                    link: `https://ag.be/over-ag/${code}`,
                },
                {
                    label: formatMessage(translations.custom.newsroom),
                    link: `https://newsroom.aginsurance.be/${code}`,
                },
                {
                    label: formatMessage(translations.custom.sustainability),
                    link: `https://ag.be/duurzaamheid/${code}`,
                },
            ],
        },
    ];

    return (
        <ul className={styles.menu}>
            {topMenuItems.map(({ link, icon: Icon, label, children }, index) => (
                <li key={index} className={styles.menuItem}>
                    <a
                        href={link}
                        className={styles.menuItemLink}
                        target={link === '#' ? '_self' : '_blank'}
                    >
                        {Icon && <Icon width={12} height={12} />}
                        {label}
                        {children?.length && <ChevronDownIcon width={8} />}
                    </a>
                    {children?.length && (
                        <ul className={styles.subMenu}>
                            {children.map((child, index) => (
                                <li key={index}>
                                    <a
                                        href={child.link}
                                        className={styles.menuItemLink}
                                        target="_blank"
                                    >
                                        {child.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
}
