'use client';

import type { TranslatedCategory } from '@prezly/sdk';
import type { Locale } from '@prezly/theme-kit-nextjs';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { SearchIcon } from '@/custom/icons';
import type { SearchSettings } from '@/types';

import styles from './SearchButton.module.scss';

const SearchWidget = dynamic(
    async () => {
        const component = await import('./SearchWidget');
        return { default: component.SearchWidget };
    },
    { ssr: false },
);

type Props = {
    localeCode: Locale.Code;
    categories: TranslatedCategory[];
    settings: SearchSettings;
};

export function SearchButton({ localeCode, categories, settings }: Props) {
    const [isOpen, setSearchOpen] = useState(false);

    function closeSearchWidget() {
        return setSearchOpen(false);
    }

    return (
        <div className={styles.search}>
            <button type="button" className={styles.button} onClick={() => setSearchOpen(true)}>
                <SearchIcon width={16} height={16} />
            </button>
            <SearchWidget
                onClose={closeSearchWidget}
                open={isOpen}
                settings={settings}
                localeCode={localeCode}
                categories={categories}
            />
        </div>
    );
}
