'use client';

import type { NewsroomLanguageSettings } from '@prezly/sdk';
import { Locale } from '@prezly/theme-kit-nextjs';
import { useRouting } from 'adapters/client/routing';
import { usePathname } from 'next/navigation';
import { Fragment, useMemo } from 'react';

import { useBroadcastedTranslations } from '@/modules/Broadcast';

import styles from './Languages.module.scss';

type AvailablePageName = 'index' | 'news' | 'blogs' | 'contacts' | 'media' | 'search';

export function Languages({ selected, languages }: Languages.Props) {
    const broadcasted = useBroadcastedTranslations();
    const { generateUrl } = useRouting();
    const pathname = usePathname();
    const routeName = getRouteName(pathname, selected);

    const options = languages.map<Languages.Option>((language) => ({
        code: language.code,
        href: generateUrl(routeName, { localeCode: language.code }),
        stories: language.public_stories_count,
        title: language.locale.native_name,
    }));

    const languageOptions = useMemo(() => {
        const displayedOptions = options.filter(
            (option) => option.code === selected || broadcasted[option.code] || option.stories > 0,
        );
        return withHrefOverrides(withShortenedTitles(displayedOptions), broadcasted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(options), JSON.stringify(selected), JSON.stringify(broadcasted)]);

    if (languageOptions.length <= 1) {
        return null;
    }

    return (
        <div className={styles.languageSwitcher}>
            {languageOptions.map((lang, index) => (
                <Fragment key={lang.code}>
                    {selected !== lang.code ? (
                        <a className={styles.languageItem} href={lang.href}>
                            {lang.title}
                        </a>
                    ) : (
                        <strong className={styles.languageItem}>{lang.title}</strong>
                    )}
                    {index < languageOptions.length - 1 && (
                        <span className={styles.separator}>|</span>
                    )}
                </Fragment>
            ))}
        </div>
    );
}

export namespace Languages {
    export interface Option {
        code: Locale.Code;
        title: string;
        href: string;
        stories: number;
    }

    export interface Props {
        selected: Locale.Code;
        languages: Pick<NewsroomLanguageSettings, 'public_stories_count' | 'code' | 'locale'>[];
        buttonClassName?: string;
        navigationItemClassName?: string;
    }
}

function withShortenedTitles(options: Languages.Option[]): Languages.Option[] {
    return options.map((option) => ({
        code: option.code,
        native_name: option.title,
        href: option.href,
        title: Locale.from(option.code).lang.toUpperCase(),
        stories: option.stories,
    }));
}

function withHrefOverrides(
    options: Languages.Option[],
    overrides: Record<Locale.Code, string | undefined>,
): Languages.Option[] {
    return options.map((option) => ({
        ...option,
        href: overrides[option.code] ?? option.href,
    }));
}

function getRouteName(pathname: string, locale: string): AvailablePageName {
    const { lang } = Locale.from(locale);
    const normalizedPathname = pathname.replace(new RegExp(`^(/${lang})?/?`), '');
    const availablePageName: AvailablePageName[] = ['blogs', 'contacts', 'media', 'news', 'search'];

    return availablePageName.includes(normalizedPathname as any)
        ? (normalizedPathname as AvailablePageName)
        : 'index';
}
