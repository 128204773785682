import type { SVGProps } from 'react';

export function Shape(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 80" fill="none" {...props}>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M590.336 145.921H0V80.233h50.463s51.018.32 76.882-6.38C195.638 58.575 213.363 2.1 298.344 0h291.992v145.921Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
